import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class AboutPage extends Component {
  render() {
    let aboutData = this.props.data.allAboutJson.nodes[0]
    let texts = []
    let i = 0
    aboutData.texts.map(text => {
      texts.push(<p key={i}>{text}</p>)
      i++
    })

    return (
      <Layout page={"about"}>
        <div className="main-about">
          <h1 className="title">{aboutData.title}</h1>
          <div className="text">
            <p>{texts}</p>
            <p><a href="/contact">Kontakt oss</a></p>
          </div>
        </div>
      </Layout>
    )
  }
}
export default AboutPage

export const query = graphql`
  query {
    allAboutJson {
      nodes {
        id
        title
        texts
      } 
    }
  }
`
